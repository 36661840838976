import { banner } from "./Banner.routes";
import { get } from "../index";

const gettingBanner = {
  getBanner: async () => {
    const result = await get(banner.getBanner);
    return result;
  },
};

export default gettingBanner;
