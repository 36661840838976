import { Box, Button, CardMedia, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/colors";
import { images } from "../../assets";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Helmet } from "react-helmet";
import gettingBanner from "../../api/banner/BannerService";
import Banner from "../../components/banner/Banner";

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [screenImage, setScreenImage] = useState("");
  const screenImages = [images.screen1, images.screen2, images.screen3];
  const [title, setTitle] = useState("ADSTREET - The Largest Advertising Marketplace");
  const [banner, setBanner] = useState([]);

  const navigate = useNavigate();
  const baseUrl = "https://adstreet.mangotech-api.com";
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const currentIndex = screenImages.indexOf(screenImage);
  //     const nextIndex = (currentIndex + 1) % screenImages.length;
  //     setScreenImage(screenImages[nextIndex]);
  //   }, 5000);

  //   return () => clearInterval(intervalId);
  // }, [screenImage, screenImages]);

  const bannerImg = [
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
  ];

  console.log("Banner img", banner);

  const handleBanner = async () => {
    try {
      let result = await gettingBanner.getBanner();
      if (result.responseCode === 200) {
        console.log("Banner Result =>", result);

        let images = result.data.banners.filter((item, index) => {
          return item?.type === "home";
        });
        console.log("Home banner =>", images);
        setBanner(images);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleBanner();

    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % screenImages.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [screenImages.length]);

  return (
    <Box>
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>
      {/* Banner section start */}
      <Box component={"section"}>
        <Banner baseUrl={baseUrl} images={banner} />
      </Box>
      {/* Banner section end */}
      <Container>
        {/* Mobile screen section start */}
        <Grid
          container
          sx={{
            mt: "40px",
            mb: "120px",
          }}
        >
          <Grid item md={8}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // marginLeft: "6px",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "44px", md: "44px", lg: "52px" },
                  fontWeight: 700,
                  textAlign: { xs: "center", sm: "none" },
                }}
                variant="h1"
              >
                WELCOME TO <span style={{ color: colors.primaryColor }}>ADSTREET</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "25px", lg: "30px" },
                  textAlign: { xs: "center", sm: "none" },
                }}
              >
                The Largest Advertising Marketplace
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "18px", lg: "24px" },
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                Revolutionize your marketing strategy with AdStreet! Seek or offer services, connect with industry leaders, access cutting edge tools and experience the future of advertising today.
              </Typography>
              <Button
                onClick={() => navigate("/signup")}
                className="joinTheWaitlist"
                sx={{
                  padding: "8px 16px",
                  color: colors.white,
                  fontWeight: "bold",
                  margin: { xs: "0 auto", md: "0" },
                  mt: { xs: 2, md: 0 },
                  fontSize: "19px",
                  backgroundColor: colors.primaryColor,
                  border: `2px solid ${colors.primaryColor}`,
                  borderRadius: "10px",
                  textDecoration: "none",
                  ":hover": {
                    backgroundColor: "transparent",
                    color: colors.textColor,
                  },
                }}
              >
                Join the waitlist
              </Button>
            </Box>
          </Grid>
          {/* <Grid item md={4}>
            <Box
              sx={{
                backgroundImage: `url(${screenImage})`,
                backgroundSize: "cover",
                width: "350px",
                height: "100%",
              }}
            >
              <CardMedia
                component={"img"}
                src={images.shell}
                sx={{
                  height: "100%",
                  width: "350px",
                  objectFit: "contain",
                  m: "0px auto",
                }}
              />
            </Box>
          </Grid> */}
          <Grid item md={4} margin={"0 auto"}>
            <Box
              sx={{
                width: { xs: "340px", md: "350px" },
                height: "100%",
                position: "relative",
                // right: { xs: "6px", md: 0 },
                overflow: "hidden",
                mt: { xs: 5, md: 0 },
              }}
            >
              {screenImages.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "absolute",
                    top: { xs: "10px", sm: "10px", md: "0" },
                    left: index * 350 + "px",
                    transition: "transform 0.5s ease-in-out",
                    transform: `translateX(-${currentIndex * 350}px)`,
                    width: { xs: "350px", sm: "350px", md: "350px" },
                    height: { xs: "94%", md: "96%" },
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    borderRadius: "50px",
                  }}
                />
              ))}
              <CardMedia
                component={"img"}
                src={images.shell}
                sx={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  objectFit: "contain",
                  height: "100%",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Mobile screen section end */}

        {/* Adventure Section */}
        <Grid container flexDirection={{ xs: "column-reverse", md: "row" }}>
          <Grid item md={4}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <CardMedia
                component={"img"}
                src={images.adstreetSign}
                sx={{
                  width: "286px",
                  height: "408px",
                  objectFit: "contain",
                  m: "0px auto",
                }}
              />
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "33px", md: "40px" },
                  fontWeight: 700,
                  textAlign: { xs: "center", md: "left" },
                  position: "relative",
                  bottom: { xs: "55px", sm: "0" },
                }}
              >
                YOUR ADVENTURE STARTS HERE
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: "28px", md: "30px" },
                  textAlign: { xs: "center", md: "left" },
                  position: "relative",
                  bottom: { xs: "50px", sm: "0" },
                }}
              >
                Scroll Down to Uncover AdStreet's Features!
              </Typography>
              <CardMedia
                component={"img"}
                src={images.homePageDropDownLogo}
                sx={{
                  width: "60px",
                  height: "60px",
                  objectFit: "contain",
                  marginTop: { xs: "10px", sm: "none" },
                  marginBottom: { xs: "20px", sm: "none" },
                  position: "relative",
                  bottom: { xs: "40px", sm: "0" },
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Adventure Section */}
      </Container>

      {/* Adbazaar Section start */}
      <Grid
        container
        sx={{
          backgroundImage: `url(${images.adbazaarBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "12px",
              pl: "67.5px",
            }}
          >
            <Box
              sx={{
                width: "250px",
              }}
            >
              <Box
                sx={{
                  p: "4px",
                  background: colors.white,
                  borderRadius: "12px",
                  marginTop: { xs: "40px", md: "0" },
                }}
              >
                <Box
                  sx={{
                    border: `4px solid ${colors.primaryColor}`,
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "30px",
                      fontWeight: 700,
                      letterSpacing: "4px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: colors.darkGrey }}>AD</span>
                    <span style={{ color: colors.primaryColor }}>BAZAAR</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "20px", sm: "30px" },
                position: "relative",
                right: { xs: "30px", sm: "0" },
                color: colors.white,
              }}
            >
              ONE BUSINESS MARKETPLACE
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "16px", sm: "24px" },
                color: colors.white,
                position: "relative",
                right: { xs: "30px", sm: "0" },
              }}
            >
              AdBazaar, a revolutionary service like never before. Select and work with industry’s top marketing players, as per your timeline and demand.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              pt: "60px",
            }}
          >
            <CardMedia
              component={"img"}
              src={images.adbazaar_ph}
              sx={{
                width: "75%",
                height: "100%",
                objectFit: "contain",
                m: "0 auto",
                marginBottom: { xs: "50px", md: "40px" },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {/* Adbazaar Section end*/}

      {/* Adleaks Section start */}
      <Grid
        container
        flexDirection={{ xs: "column-reverse", sm: "row-reverse", md: "row" }}
        sx={{
          backgroundImage: `url(${images.adleaksBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              pt: "40px",
            }}
          >
            <CardMedia
              component={"img"}
              src={images.adleaks_ph}
              sx={{
                width: "75%",
                height: "100%",
                objectFit: "contain",
                marginBottom: { xs: "40px", md: "40px" },
                m: "0 auto",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "12px",
              pr: "67.5px",
            }}
          >
            <Box
              sx={{
                width: "250px",
                marginTop: { xs: "40px", sm: "0" },
                marginLeft: { xs: "70px", md: "0" },
              }}
            >
              <Box
                sx={{
                  p: "4px",
                  background: colors.white,
                  borderRadius: "12px",
                }}
              >
                <Box
                  sx={{
                    border: `4px solid ${colors.orange}`,
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "26px", sm: "30px" },
                      fontWeight: 700,
                      letterSpacing: "4px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: colors.darkGrey }}>AD</span>
                    <span style={{ color: colors.orange }}>LEAKS</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "20px", sm: "30px" },
                textAlign: { xs: "center", sm: "left" },
                marginLeft: { xs: "15%", sm: "0" },
                position: "relative",
                left: { xs: "10px", sm: "70px", md: "0" },
                color: colors.white,
              }}
            >
              LATEST NEWS, UPDATES & GOSSIP
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "16px", md: "24px" },
                position: "relative",
                left: { xs: "30px", sm: "70px", md: "0" },
                textAlign: { xs: "center", sm: "left" },
                color: colors.white,
              }}
            >
              Stay ahead of the curve with AdLeaks' industry news.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Adleaks Section end */}

      {/* Adbooks Section start */}
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row", md: "row" }}
        sx={{
          backgroundImage: `url(${images.adbooksBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "12px",
              pl: "67.5px",
            }}
          >
            <Box
              sx={{
                width: "250px",
                marginTop: { xs: "40px", sm: "0" },
              }}
            >
              <Box
                sx={{
                  p: "4px",
                  background: colors.white,
                  borderRadius: "12px",
                }}
              >
                <Box
                  sx={{
                    border: `4px solid ${colors.yellow}`,
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "26px", sm: "30px" },
                      fontWeight: 700,
                      letterSpacing: "4px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: colors.darkGrey }}>AD</span>
                    <span style={{ color: colors.yellow }}>BOOKS</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "20px", sm: "30px" },
                position: "relative",
                right: { xs: "24px", sm: "0" },
                left: { sm: "4px", md: "0" },
                color: colors.white,
              }}
            >
              CONNECT WITH THE MARKET
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "16px", md: "24px" },
                color: colors.white,
                position: "relative",
                right: { xs: "10%", sm: "0" },
                left: { sm: "4px", md: "0" },
              }}
            >
              A guidebook to Connect with Industry Leaders, Companies and Vendors. Share knowledge and achieve goals!
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              pt: "60px",
            }}
          >
            <CardMedia
              component={"img"}
              src={images.adbooks_ph}
              sx={{
                width: "85%",
                height: "100%",
                objectFit: "contain",
                m: "0 auto",
                marginBottom: { xs: "50px", md: "40px" },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {/* Adbooks Section end */}

      {/* Admeet Section start */}
      <Grid
        container
        flexDirection={{ xs: "column-reverse", sm: "row-reverse", md: "row" }}
        sx={{
          backgroundImage: `url(${images.admeetBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              pt: "40px",
            }}
          >
            <CardMedia
              component={"img"}
              src={images.admeet_ph}
              sx={{
                width: "85%",
                height: "100%",
                objectFit: "contain",
                m: "0 15%",
                marginBottom: { xs: "50px", md: "40px" },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "12px",
              pr: "67.5px",
            }}
          >
            <Box
              sx={{
                width: "250px",
              }}
            >
              <Box
                sx={{
                  p: "4px",
                  background: colors.white,
                  borderRadius: "12px",
                  marginTop: { xs: "20%", sm: "0" },
                  position: "relative",
                  left: { xs: "28%", sm: "70px", md: "0" },
                }}
              >
                <Box
                  sx={{
                    border: `4px solid ${colors.lightBlue}`,
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "30px",
                      fontWeight: 700,
                      letterSpacing: "4px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: colors.darkGrey }}>ADMEET</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "20px", sm: "30px" },
                position: "relative",
                left: { xs: "30px", sm: "70px", md: "0" },
                textAlign: { xs: "center", sm: "left" },
                color: colors.white,
              }}
            >
              INSPIRING MARKETING EVENTS & MEET UPS
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "16px", md: "24px" },
                color: colors.white,
                position: "relative",
                left: { xs: "30px", sm: "70px", md: "0" },
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              Connect, learn and have fun with like minded marketers worldwide at various events.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Admeet Section end */}

      {/* Adguru Section start */}
      <Grid
        container
        sx={{
          backgroundImage: `url(${images.adguruBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "12px",
              pl: "67.5px",
            }}
          >
            <Box
              sx={{
                width: "250px",
              }}
            >
              <Box
                sx={{
                  p: "4px",
                  background: colors.white,
                  borderRadius: "12px",
                  marginTop: { xs: "50px", sm: "60px", md: "0" },
                }}
              >
                <Box
                  sx={{
                    border: `4px solid ${colors.darkGrey2}`,
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", sm: "30px" },
                      fontWeight: 700,
                      letterSpacing: "4px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: colors.darkGrey }}>AD</span>
                    <span style={{ color: colors.textColor }}>GURU</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "20px", sm: "30px" },
                position: "relative",
                right: { xs: "16px", sm: "0" },
                color: colors.white,
              }}
            >
              TRAINING & DEVELOPMENT
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "16px", md: "24px" },
                color: colors.white,
                position: "relative",
                right: { xs: "30px", sm: "0" },
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              Unlock your true potential with AdGuru's advertising training programs by Market Leaders.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              pt: "60px",
            }}
          >
            <CardMedia
              component={"img"}
              src={images.adguru_ph}
              sx={{
                width: "70%",
                height: "100%",
                objectFit: "contain",
                m: "0 auto",
                marginBottom: { xs: "50px", md: "40px" },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {/* Adguru Section end */}

      {/* Adpro Section start */}
      <Grid
        container
        flexDirection={{ xs: "column-reverse", sm: "row-reverse", md: "row" }}
        sx={{
          backgroundImage: `url(${images.proServicesBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              pt: "40px",
            }}
          >
            <CardMedia
              component={"img"}
              src={images.adpro_ph}
              sx={{
                width: "75%",
                height: "100%",
                objectFit: "contain",
                m: "0 auto",
                marginBottom: { xs: "50px", md: "40px" },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "12px",
              pr: "67.5px",
            }}
          >
            <Box
              sx={{
                width: { xs: "300", sm: "340", md: "375px" },
                marginTop: { xs: "40px", sm: "0" },
                position: "relative",
                left: { xs: "28px", sm: "56px", md: "0" },
              }}
            >
              <Box
                sx={{
                  p: "4px",
                  background: colors.white,
                  borderRadius: "12px",
                }}
              >
                <Box
                  sx={{
                    border: `4px solid ${colors.blackShade}`,
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "24px", md: "30px" },
                      fontWeight: 700,
                      letterSpacing: "4px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: colors.darkGrey }}>AD</span>
                    <span style={{ color: colors.blackShade }}>PRO SERVICES</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "20px", sm: "30px" },
                color: colors.white,
                position: "relative",
                left: { xs: "30px", sm: "60px", md: "0" },
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              ONE STOP ADVERTISING SHOP
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "16px", sm: "24px" },
                color: colors.white,
                position: "relative",
                left: { xs: "35px", sm: "60px", md: "0" },
              }}
            >
              You’ll get all the marketing solutions tailored by AdPro Services, whatever and whenever!
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Adpro Section end */}

      {/* Adread Section start */}
      <Grid
        container
        sx={{
          backgroundImage: `url(${images.adreadBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          pb: { xs: 1, md: 3 },
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "12px",
              pl: "67.5px",
            }}
          >
            <Box
              sx={{
                width: { xs: "270px", sm: "250px" },
                marginTop: { xs: "40px", sm: "0" },
                position: "relative",
                right: { xs: "30px" },
              }}
            >
              <Box
                sx={{
                  p: "4px",
                  background: colors.white,
                  borderRadius: "12px",
                  marginLeft: { xs: "40px" },
                }}
              >
                <Box
                  sx={{
                    border: `4px solid ${colors.maroon}`,
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "23px", sm: "30px" },
                      fontWeight: 700,
                      letterSpacing: "4px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: colors.darkGrey }}>AD</span>
                    <span style={{ color: colors.maroon }}>READ</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "20px", sm: "30px" },
                color: colors.white,
                position: "relative",
                right: { xs: "35px", sm: "0" },
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              INDUSTRY’S EXPERT OPINIONS
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "16px", md: "24px" },
                color: colors.white,
                position: "relative",
                right: { xs: "35px", sm: "0" },
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              Unlock exclusive marketing insights and strategies from industry experts on AdRead.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              pt: "60px",
            }}
          >
            <CardMedia
              component={"img"}
              src={images.adread_ph}
              sx={{
                width: "75%",
                height: "100%",
                objectFit: "contain",
                m: "0 auto",
                marginBottom: { xs: "50px", md: "40px" },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {/* Adread Section end */}
    </Box>
  );
};

export default Home;
