import React from 'react'
import { Box, CardMedia } from '@mui/material';
import { images } from '../../assets';

function EventPartner() {

  return (
    <Box>
      <CardMedia
        component={"img"}
        src={images.dragonEvpt}
        sx={{
          width: "100%",
          height: "100%"
        }}
      />
    </Box>
  )
}

export default EventPartner;