import React from "react";
import { Box, Container, Grid, CardMedia, Button } from "@mui/material";
import * as projectImages from "../../assets/index";
import { colors } from "../../assets/colors";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoginService from "../../api/login/LoginService";
import { useNavigate } from "react-router-dom";

const OtherSignup = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const loginForm = async (formData) => {
    const obj = {
      email: formData?.email,
      password: formData?.password,
      fcmToken: "test",
    };

    try {
      const result = await LoginService.logIn(obj);
      if (result.responseCode == 200) {
        // alert("Form submitted");
        localStorage.setItem("token", result?.data?.user?.token);
        navigate("/admeet-registeration");
      }
    } catch (err) {}
  };
  return (
    <Box component={"main"}>
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <Box
              component={"section"}
              sx={{
                width: { xs: "100%", sm: "60%", md: "50%" },
                minHeight: "52vh",
                // height: "auto",
                boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
                m: "0 auto",
                mt: 10,
                mb: 10,
                p: { sm: 2 },
              }}
            >
              <Grid container rowGap={"5px"}>
                {/* Logo */}
                <Grid item xs={12} sx={{ margin: "0 auto" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CardMedia
                      component={"img"}
                      src={projectImages.images.logo}
                      alt="Web app logo"
                      sx={{
                        width: "50%",
                        mt: 2,
                      }}
                    />
                  </Box>
                </Grid>
                {/* Signup heading */}
                <Grid item xs={12}>
                  <Box component={"h1"} sx={{ textAlign: "center", textTransform: "capitalize" }}>
                    Log In
                  </Box>
                </Grid>
                {/* Form */}
                <Box component="form" onSubmit={handleSubmit(loginForm)} sx={{ width: "100%" }}>
                  {/* email */}
                  <Grid item xs={12}>
                    <Box sx={{ p: 1 }}>
                      <FormControl
                        sx={{
                          width: {
                            xs: "100%",
                          },
                          "& fieldset": {
                            borderColor: colors.borderColor,
                          },
                        }}
                        variant="outlined"
                        error={!!errors.email}
                      >
                        <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type="email"
                          placeholder="Email"
                          startAdornment={
                            <InputAdornment position="end">
                              <IconButton sx={{ color: colors.primaryColor, m: 0 }} aria-label="toggle password visibility" edge="end">
                                <EmailIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Email"
                          {...register("email", {
                            required: "Enter your email",
                          })}
                        />
                        <FormHelperText>{errors?.email?.message}</FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/* Password */}
                  <Grid item xs={12}>
                    <Box sx={{ p: 1 }}>
                      <FormControl
                        sx={{
                          width: {
                            xs: "100%",
                          },
                        }}
                        variant="outlined"
                        error={!!errors.password}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          startAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                sx={{ color: colors.primaryColor, m: 0 }}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          {...register("password", {
                            required: "Enter your password",
                          })}
                        />
                        <FormHelperText>{errors?.password?.message}</FormHelperText>
                      </FormControl>
                      <Button
                        type="submit"
                        sx={{
                          backgroundColor: colors.primaryColor,
                          width: "100%",
                          color: colors.white,
                          mt: 2,
                          "&:hover": { backgroundColor: colors.primaryColor },
                        }}
                      >
                        Log In
                      </Button>
                    </Box>
                  </Grid>
                </Box>

                {/*   Or sign in with  */}
                <Grid item xs={12}>
                  <Box
                    component={"h5"}
                    sx={{
                      textAlign: "center",
                      color: colors.darkGrey,
                    }}
                  >
                    Or sign in with
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"} columnGap={"10px"}>
                    {/* Google */}
                    <Grid
                      item
                      xs={2}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Button sx={{ height: "40px", backgroundColor: colors.white, boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`, "&:hover": { backgroundColor: colors.white } }}>
                        <CardMedia component={"img"} sx={{ width: "20px", height: "20px" }} src={projectImages.images.googleLogo} />
                      </Button>
                    </Grid>
                    {/* Facebook */}
                    <Grid
                      item
                      xs={2}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Button sx={{ height: "40px", backgroundColor: colors.white, boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`, "&:hover": { backgroundColor: colors.white } }}>
                        <FacebookIcon />
                      </Button>
                    </Grid>
                    {/* Email */}
                    <Grid
                      item
                      xs={2}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Button sx={{ height: "40px", backgroundColor: colors.white, boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`, "&:hover": { backgroundColor: colors.white } }}>
                        <EmailIcon
                          sx={{
                            color: colors.darkGrey2,
                          }}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    component={"h5"}
                    sx={{
                      textAlign: "center",
                      color: colors.darkGrey,
                    }}
                  >
                    Don't have an account?
                    <Box
                      style={{
                        color: colors.primaryColor,
                        textDecoration: "none",
                      }}
                      component={Link}
                      to={"/signup"}
                    >
                      Sign up
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OtherSignup;
