import IndustriesRoutes from "./Industries.routes";
import { get } from "../index";

const IndustriesService = {
  getAllIndustries: async () => {
    const result = await get(IndustriesRoutes.getAllIndustries);
    return result;
  }
}

export default IndustriesService;