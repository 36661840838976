import React from "react";
import { Box, Button, CardMedia, Grid } from "@mui/material";
import * as projectImages from "../../assets/index";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { colors } from "../../assets/colors/index";
import { Tiktok } from "../../assets/index";
import { Link } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import "./index.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const returnToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navigateSignup = () => {
    navigate("/signup");
    returnToTop();
  };

  return (
    <>
      <Box
        component={"footer"}
        aria-label="footer"
        sx={{
          width: "100%",
        }}
      >
        {/* Footer 1st section */}
        <Grid
          container
          sx={{
            backgroundColor: colors.footerSectionOneBgColor,
            pt: 4,
            pb: { xs: 5, lg: 0 },
          }}
        >
          <Grid
            item
            md={4}
            sx={{
              textAlign: "center",
              display: { xs: "none", lg: "block" },
              height: "100%",
            }}
          >
            <Box>
              <CardMedia
                component={"img"}
                src={projectImages.images.footerSectionOneImg}
                sx={{
                  width: "300px",
                  height: "300px",
                  objectFit: "contain",
                  pl: 6,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} pl={{ xs: 1, lg: 0 }}>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  component={"h2"}
                  sx={{
                    color: colors.white,
                    fontSize: { xs: "29px", md: "43px" },
                    m: 0,
                    mt: 4,
                    textAlign: { xs: "center", lg: "left" },
                  }}
                >
                  Join the VIP Queue
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  component={"h6"}
                  sx={{
                    color: colors.white,
                    fontWeight: "light",
                    fontSize: { xs: "22px", md: "30px" },
                    m: 0,
                    mt: 2,
                    textAlign: { xs: "center", lg: "left" },
                  }}
                >
                  Reserve your spot on the waitlist for priority access.
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} sm={3} lg={6} margin={{ xs: "0 auto", lg: "0" }}>
                <Button
                  onClick={() => navigateSignup()}
                  sx={{
                    color: colors.white,
                    margin: "0 auto",
                    display: { xs: "block", md: "inline" },
                    mt: { xs: 3, lg: 2 },
                    pl: { xs: 3, sm: 1, md: 0, lg: 3 },
                    pr: { xs: 3, sm: 1, md: 0, lg: 3 },
                    fontWeight: "bold",
                    borderRadius: "8px",

                    border: `3px solid ${colors.primaryColor}`,
                    backgroundColor: colors.primaryColor,
                    "&:hover": {
                      border: `3px solid ${colors.primaryColor}`,
                    },
                  }}
                >
                  Join the waitlist
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Footer 2nd section */}
        <Grid
          container
          sx={{
            backgroundColor: colors.footerSectionTwoBgColor,
            width: "100%",
            height: "auto",
            pt: { xs: 6 },
            pb: { lg: 5 },
          }}
        >
          {/* What we do section */}
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              pl: { md: 1 },
            }}
          >
            <Grid container>
              {/* Adstreet logo */}
              <Grid item xs={12}>
                <Box
                  component={"img"}
                  src={projectImages.images.logo}
                  alt="Adstreet logo"
                  sx={{
                    width: "250px",
                    display: "block",
                    margin: { xs: "0 auto", md: "0" },
                  }}
                />
              </Grid>
              {/* What we do heading */}
              <Grid item xs={12}>
                <Box
                  component={"h5"}
                  sx={{
                    textTransform: "uppercase",
                    color: colors.white,
                    m: 0,
                    fontSize: "19px",
                    mt: { xs: 2, sm: 2, md: 2 },
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  What we do
                </Box>
              </Grid>
              {/* Whether you are */}
              <Grid item xs={12} sm={12} md={10}>
                <Box
                  component={"p"}
                  sx={{
                    color: colors.white,
                    fontSize: "16px",
                    lineHeight: "25px",
                    pr: { xs: 1, sm: 3, md: 0 },
                    pl: { xs: 1, sm: 3, md: 0 },
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Whether you're a disruptor looking to make waves, a passionate marketer seeking growth or a brand ready to make a lasting impact, AdStreet is your gateway to success.
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* Quick links and get in touch */}
          <Grid item xs={12} md={6} lg={6}>
            <Grid container>
              {/* Quick links start */}
              <Grid item xs={12} md={5} lg={5}>
                <Box
                  component={"h4"}
                  sx={{
                    color: colors.white,
                    margin: "0",
                    textTransform: "uppercase",
                    fontSize: "19px",
                    textAlign: { xs: "center", md: "left" },
                    mt: { xs: 1, md: 0 },
                  }}
                >
                  Quick Links
                </Box>
                {/* Return to top */}
                <ListItemText
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                  }}
                  onClick={returnToTop}
                >
                  <Link
                    className="footer-link"
                    to="#"
                    style={{
                      fontSize: "16px",
                      color: colors.white,
                      textDecoration: "none",
                      marginTop: "15px",
                      transition: "0.3s",
                      paddingLeft: "10px",
                      display: "block",
                    }}
                  >
                    Return to Top
                  </Link>
                </ListItemText>
                {/* About us */}
                <ListItemText
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                  }}
                  onClick={returnToTop}
                >
                  <Link
                    className="footer-link"
                    to="/about"
                    style={{
                      fontSize: "16px",
                      color: colors.white,
                      textDecoration: "none",
                      marginTop: "15px",
                      transition: "0.3s",
                      paddingLeft: "10px",
                      display: "block",
                    }}
                  >
                    About us
                  </Link>
                </ListItemText>
                {/* Sign up */}
                <ListItemText
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                  }}
                  onClick={returnToTop}
                >
                  <Link
                    className="footer-link"
                    to="/signup"
                    style={{
                      fontSize: "16px",
                      color: colors.white,
                      textDecoration: "none",
                      marginTop: "15px",
                      transition: "0.3s",
                      paddingLeft: "10px",
                      display: "block",
                    }}
                  >
                    Sign Up
                  </Link>
                </ListItemText>
                {/* Privacy policy */}
                <ListItemText
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                  }}
                  onClick={returnToTop}
                >
                  <Link
                    className="footer-link"
                    to="/privacy-policy"
                    style={{
                      fontSize: "16px",
                      color: colors.white,
                      textDecoration: "none",
                      marginTop: "15px",
                      transition: "0.3s",
                      paddingLeft: "10px",
                      display: "block",
                    }}
                  >
                    Privacy Policy
                  </Link>
                </ListItemText>
                {/* Terms of use */}
                <ListItemText
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                  }}
                  onClick={returnToTop}
                >
                  <Link
                    className="footer-link"
                    to="/terms-of-use"
                    style={{
                      fontSize: "16px",
                      color: colors.white,
                      textDecoration: "none",
                      marginTop: "15px",
                      transition: "0.3s",
                      paddingLeft: "10px",
                      display: "block",
                    }}
                  >
                    Terms of Use
                  </Link>
                </ListItemText>
              </Grid>
              {/* Quick links end */}
              {/* Get in touch start */}
              <Grid item xs={12} md={7} lg={6}>
                <Box
                  component={"h4"}
                  sx={{
                    color: colors.white,
                    margin: "0",
                    textTransform: "uppercase",
                    fontSize: "19px",
                    textAlign: { xs: "center", md: "left" },
                    mt: { xs: 3, md: 0 },
                  }}
                >
                  Get in touch
                </Box>
                <Box
                  component={"h6"}
                  sx={{
                    fontSize: "16px",
                    color: colors.white,
                    fontWeight: "light",
                    textTransform: "uppercase",
                    m: 0,
                    mt: 3,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Adstreet
                </Box>
                <Box
                  component={"h6"}
                  sx={{
                    fontSize: "16px",
                    color: colors.white,
                    fontWeight: "light",
                    m: 0,
                    mt: 1,
                    textAlign: { xs: "center", md: "left" },
                    p: { xs: 1, md: 0 },
                    lineHeight: "25px",
                  }}
                >
                  Level B, 1 C, Jami Commercial, Street 3, DHA, Phase VII, Karachi
                </Box>
                <Box
                  component={"h6"}
                  sx={{
                    fontSize: "16px",
                    color: colors.white,
                    fontWeight: "light",
                    m: 0,
                    mt: 1,
                    textAlign: { xs: "center", md: "left" },
                    lineHeight: "25px",
                  }}
                >
                  Phone: 021-34313245-48
                </Box>
                <Box
                  component={"div"}
                  sx={{
                    mt: 2,
                  }}
                >
                  <Grid container justifyContent={{ xs: "center", md: "flex-start" }} mb={{ xs: 2, md: 0 }}>
                    {/* Facebook */}
                    <Grid item xs={1} md={2}>
                      <Box
                        component={"a"}
                        href="https://www.facebook.com/OfficialAdStreet"
                        target="_blank"
                        alt="Connet us on Facebook"
                        sx={{
                          color: colors.white,
                          pl: { md: 1 },
                          textAlign: { xs: "center", md: "left" },
                          display: "block",
                          transition: "0.2s",
                          "&:hover": {
                            color: colors.primaryColor,
                          },
                        }}
                      >
                        <FacebookIcon />
                      </Box>
                    </Grid>
                    {/* Instagram */}
                    <Grid item xs={1} md={1}>
                      <Box
                        component={"a"}
                        href="https://www.instagram.com/adstreetofficial/"
                        target="_blank"
                        alt="Connet us on Instagram"
                        sx={{
                          color: colors.white,
                          display: "block",
                          textAlign: { xs: "center", md: "left" },
                          transition: "0.2s",
                          "&:hover": {
                            color: colors.primaryColor,
                          },
                        }}
                      >
                        <InstagramIcon />
                      </Box>
                    </Grid>
                    {/* Twitter */}
                    <Grid item xs={1} md={2}>
                      <Box
                        component={"a"}
                        href="https://twitter.com/theAdStreet"
                        target="_blank"
                        alt="Connet us on Twitter"
                        sx={{
                          color: colors.white,
                          display: "block",
                          textAlign: { xs: "center", md: "left" },
                          transition: "0.2s",
                          pl: { md: 2 },
                          "&:hover": {
                            color: colors.primaryColor,
                          },
                        }}
                      >
                        <TwitterIcon />
                      </Box>
                    </Grid>
                    {/* Tiktok */}
                    <Grid item xs={1} md={1}>
                      <Box
                        component={"a"}
                        href="https://www.tiktok.com/@adstreetofficial"
                        target="_blank"
                        alt="Connect us on Tiktok"
                        sx={{
                          display: "block",
                          textAlign: { xs: "center", md: "left" },
                          fill: colors.white,
                          ":hover": {
                            fill: colors.primaryColor,
                          },
                        }}
                      >
                        <Tiktok />
                      </Box>
                    </Grid>
                    {/* Linkedin */}
                    <Grid item xs={1} md={1}>
                      <Box
                        component={"a"}
                        href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQH9nFTbWQutDQAAAYkrniXgpFlQu5ymyg9Bznjxt5RPnOlhOtq3kc-VjGH9UeM23zRClsoMHLApJXZ-oDvCKKl1rGzBbb2GFFQZ-Vz9SA42L_-jjPsUBk1HgTdoh5GX_TWlXds=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F96397314"
                        target="_blank"
                        alt="Connet us on LinkedIn"
                        sx={{
                          color: colors.white,
                          display: "block",
                          textAlign: { xs: "center", md: "left" },
                          transition: "0.2s",
                          pl: { md: 1 },
                          "&:hover": {
                            color: colors.primaryColor,
                          },
                        }}
                      >
                        <LinkedInIcon />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* Get in touch end */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
