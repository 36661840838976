import AdproRoutes from "./Adpro.routes";
import { post } from "../index";

const AdproService = {
  register: async (obj) => {
    const result = await post(AdproRoutes.register, obj);
    return result;
  },
  uploadFile: async (obj) => {
    const result = await post(AdproRoutes.uploadFile, obj);
    return result;
  }
}

export default AdproService;