import { get } from "../index";
import { cities } from "./Cities.routes";

const getCities = {
  gettingCities: async (id) => {
    const result = await get(cities.getCities + `/${id}`);
    return result;
  },
};

export default getCities;
