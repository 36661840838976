import { TextField } from "@mui/material";
import React from "react";
import { colors } from "../../assets/colors";

function InputField({ placeholder, multiline, rows, maxRows, InputProps, register, error, helperText, admeet }) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      InputProps={InputProps}
      maxRows={maxRows}
      {...register}
      error={error}
      helperText={helperText}
      sx={{
        "&:hover": {
          "& fieldset": {
            borderColor: `${colors.white} !important`,
          },
        },
        "&:focus": {
          borderColor: `${colors.white} !important`,
          borderWidth: "1px !important",
        },
        "& fieldset": {
          borderColor: `${colors.white} !important`,
          borderWidth: "1px !important",
          borderRadius: "8px",
        },
        input: {
          color: admeet ? colors.textColor : colors.white,
          "&::placeholder": {
            color: admeet ? colors.textColor : colors.white,
          },
        },
      }}
    />
  );
}

export default InputField;
