import { FormControl, MenuItem, Select } from "@mui/material";
import { colors } from "../../assets/colors";
import { v4 as uuidv4 } from "uuid";
function SelectField({ data, value, setValue, selectType, register, disabled }) {
  console.log("Value =>", value);
  return (
    <FormControl fullWidth>
      <Select
        {...register}
        sx={{
          "&:hover": {
            "& fieldset": {
              borderColor: `${colors.white} !important`,
            },
          },
          "&:focus": {
            borderColor: `${colors.white} !important`,
            borderWidth: "1px !important",
          },
          "& fieldset": {
            borderColor: `${colors.white} !important`,
            borderWidth: "1px !important",
            borderRadius: "8px",
          },
          "& .MuiSelect-icon": {
            color: `${colors.white} !important`,
          },
          "& .MuiMenuItem-root": {
            color: `${colors.white} !important`,
          },
          "& .MuiInputBase-input": {
            color: selectType == "admeet" ? `${colors.textColor} !important` : `${colors.white} !important`,
          },
          color: `${colors.white} !important`,
        }}
        value={value == "" ? 0 : value}
      >
        {selectType == "Member" ? (
          <MenuItem value={0}>Which Industry Do You Work In?</MenuItem>
        ) : selectType == "service" || selectType == "admeet" ? (
          <MenuItem value={0}>Which Industry Do You Work In?</MenuItem>
        ) : selectType == "category" || selectType == "admeet" ? (
          <MenuItem value={0}>Select Your Category (Required)</MenuItem>
        ) : selectType == "country" || selectType == "admeet" ? (
          <MenuItem value={0}>Select Your Country (Required)</MenuItem>
        ) : selectType == "state" || selectType == "admeet" ? (
          <MenuItem disabled value={0}>
            Select Your State (Required)
          </MenuItem>
        ) : selectType == "city" || selectType == "admeet" ? (
          <MenuItem disabled value={0}>
            Select Your City (Required)
          </MenuItem>
        ) : selectType == "state" || selectType == "admeet" ? (
          <MenuItem value={0}>Select Your State (Required)</MenuItem>
        ) : selectType == "Provider" ? (
          <MenuItem value={0}>What type of services do you offer?</MenuItem>
        ) : selectType == "Seeker" ? (
          <MenuItem value={0}>What is your Industry Type?</MenuItem>
        ) : selectType == "adpro" ? (
          <MenuItem value={0}>Mention Your Service (Required)</MenuItem>
        ) : selectType == "adproInd" || selectType == "admeet" ? (
          <MenuItem sx={{}} value={0}>
            Mention Your Industry (Required)
          </MenuItem>
        ) : selectType == "adproCat" || selectType == "admeet" ? (
          <MenuItem value={0}>Mention Your Category (Required)</MenuItem>
        ) : (
          <></>
        )}
        {Array.isArray(data) &&
          data?.map((item, ind) => (
            <MenuItem
              key={ind}
              value={item._id}
              onClick={() => {
                console.log(item);
                setValue(item);
              }}
            >
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default SelectField;

