import React, { useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import PolicyAndTerms from "../../components/privacyAndTermsOfUse/PolicyAndTerms";
import { data } from "./index";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  const [title, setTitle] = useState("Privacy Policy - ADSTREET");
  return (
    <>
      <Box component={"div"}>
        <Helmet>
          <title>{title ? title : "No title"}</title>
        </Helmet>
        <Container>
          <Box
            component={"h1"}
            sx={{
              textAlign: "center",
              mt: 5,
            }}
          >
            Privacy policy
          </Box>
          <Grid container>
            {/* Last Updated: 1st August 2023*/}
            <Grid item xs={12}>
              {data.map((item, index) => (
                <>
                  {/* Title */}
                  <Box
                    sx={{
                      marginTop: item?.style?.mTitle,
                    }}
                  >
                    <PolicyAndTerms title={item?.title} />
                  </Box>
                  {/* Paragraph 1*/}
                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                    }}
                  >
                    <PolicyAndTerms paragraph={item?.paragraph} />
                  </Box>
                  {/* Paragraph 2*/}
                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                    }}
                  >
                    <PolicyAndTerms paragraph={item?.paragraphTwo} />
                  </Box>

                  {/* Paragraph 3 */}

                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                      marginBottom: item?.style?.mbPara,
                    }}
                  >
                    <PolicyAndTerms paragraph={item?.paragraphThree} />
                  </Box>

                  {/* text */}
                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                    }}
                  >
                    <PolicyAndTerms text={item?.text} />
                  </Box>

                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                    }}
                  >
                    <PolicyAndTerms list={item?.listOne} />
                    <PolicyAndTerms list={item?.listTwo} />
                    <PolicyAndTerms list={item?.listThree} />
                    <PolicyAndTerms list={item?.listFour} />
                    <PolicyAndTerms list={item?.listFive} />
                  </Box>
                </>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
