import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import * as projectImages from "../../assets/index";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Typography from "@mui/material/Typography";
import { colors } from "../../assets/colors/index";
import AdleaksService from "../../api/adleaks/AdleaksService";
import moment from "moment";
import { baseUrl } from "../../axios";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import gettingBanner from "../../api/banner/BannerService";

import "./adleaks.css";
const Adleaks = () => {
  const [adleaks, setAdleaks] = useState([]);
  const [title, setTitle] = useState("ADLEAKS - ADSTREET");
  const [banner, setBanner] = useState([]);

  const navigate = useNavigate();


  const baseUrl = "https://adstreet.mangotech-api.com";


  const getAllAdleaks = async () => {
    try {
      const result = await AdleaksService.getAllAdleaks(1, 100, "adleak");
      if (result.responseCode == 200) {
        console.log("Result data blogs", result?.data?.blogs);
        setAdleaks(result?.data?.blogs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const returnToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to filter only <p> tags from HTML content
  // Function to extract and render text content within <p> tags
  const renderOnlyParagraphs = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Select all paragraphs within the tempDiv
    const paragraphs = tempDiv.querySelectorAll("p");

    let paragraphText = "";
    // Concatenate the text content of each paragraph
    paragraphs.forEach((paragraph) => {
      paragraphText += paragraph.textContent + " "; // Concatenating text content with a space
    });

    return paragraphText;
  };

  const bannerImg = [
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
  ];

  const handleBanner = async () => {
    try {
      let result = await gettingBanner.getBanner();
      if (result.responseCode === 200) {
        console.log("Banner Result =>", result);
        let images = result.data.banners.filter((item, index) => {
          return item?.type === "adleak";
        });
        console.log("Adleaks banner =>", images);
        setBanner(images);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllAdleaks();
    handleBanner();
  }, []);
  return (
    <>
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>
      <Box component={"main"}>
        {/* Adleaks bg section */}
        <Box
          component={"section"}
          sx={{
            backgroundImage: `url(${projectImages.images.adleaksPageBg})`,
            width: "100%",
            height: "auto",
            backgroundPosition: "center",
            backgroundSize: "cover",
            pt: 2,
            pb: 4,
          }}
        >
          <Box
            component={"img"}
            src={projectImages.images.adleaksTextImg}
            sx={{
              display: "block ",
              margin: "0 auto",
              mt: { xs: 4, md: 4 },
            }}
          />
          <Box
            component={"h2"}
            sx={{
              color: colors.white,
              textAlign: "center",
              fontSize: { xs: "15px", md: "18.72px" },
              mt: { xs: 3.5, sm: 3, md: 2 },
              ml: "auto",
              mr: "auto",
              width: "90%",
            }}
          >
            Stay ahead of the curve with ADLEAKS' industry news updates!
          </Box>
        </Box>
        <Container>
          <Grid
            container
            rowGap={2}
            columnSpacing={2}
            sx={{
              mt: "0px",
              mb: "30px",
            }}
          >
            <Grid item md={12}>
              <Box sx={{ pt: 2 }}>
                <Banner baseUrl={baseUrl} images={banner} />
              </Box>
            </Grid>
            {adleaks.map(
              (item, index) =>
                item?.type == "adleak" && item.status == true && (
                  <Grid key={index} item xs={12} sm={6} lg={4}>
                    {console.log("Getting item =>", item)}
                    <Card
                      sx={{
                        maxWidth: { xs: 340, sm: 280, md: 400 },
                        margin: "0 auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: 0,
                        boxShadow: "none",
                      }}
                    >
                      <Box
                        component={"img"}
                        onClick={() => navigate(`/adleaks-blog/${item?._id}`, { state: item })}
                        sx={{ height: 210, width: "100%", objectFit: { xs: "contain", md: "cover" }, cursor: "pointer" }}
                        src={baseUrl + item?.image}
                        alt="Card image"
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          p: 0,
                          // pt: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%"
                          }}
                        >
                          <Box
                            sx={{
                              textDecoration: "none",
                              color: colors.cardTitleColor,
                              // height: { xs: "auto", md: "auto" },
                              backgroundColor: "transparent",
                              border: "none",
                              textAlign: "left",
                              cursor: "pointer",
                              display: "flex",
                              p: 0,
                              mt: { xs: 0, md: 2 },
                            }}
                            onClick={() => navigate(`/adleaks-blog/${item?._id}`, { state: item })}
                            gutterBottom
                            variant="h6"
                            fontWeight={"bold"}
                            fontSize={"18px"}
                          >
                            {item?.title}
                          </Box>
                          <Typography
                            sx={{
                              width: { xs: "330px", sm: "280px", lg: "400px" },
                            }}
                            gutterBottom
                            component="small"
                            fontSize={"12px"}
                            display={"block"}
                            mt={"3px"}
                            color={colors.cardsDataColor}
                          >
                            {moment(item?.date).format("LL")}
                          </Typography>
                        </Box>
                        {item?.content && (
                          <Typography className="parse-content" sx={{ m: 0 }} component={"p"} color={colors.blogCardsTextColor} fontSize={"14px"}>
                            {renderOnlyParagraphs(item?.content.slice(0, 190))}
                          </Typography>
                        )}
                      </CardContent>
                      <CardActions
                        sx={{
                          p: 0,
                        }}
                      >
                        <Box
                          component={"a"}
                          sx={{
                            fontSize: "small",
                            textDecoration: "none",
                            color: colors.textColor,
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            cursor: "pointer",
                            p: 0,
                            mt: 0.2,
                          }}
                        >
                          <Button
                            onClick={() => navigate(`/adleaks-blog/${item?._id}`, { state: item }, returnToTop())}
                            sx={{ color: colors.textColor, fontSize: "12px", fontWeight: "bold", p: 0, mt: 0 }}
                          >
                            Read more
                          </Button>
                          <KeyboardDoubleArrowRightIcon sx={{ fontSize: "small", position: "relative", top: "3px" }} />
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                )
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Adleaks;
