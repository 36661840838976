import React, { useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import PolicyAndTerms from "../../components/privacyAndTermsOfUse/PolicyAndTerms";
import { data } from "./index";
import { Helmet } from "react-helmet";
const Terms = () => {
  const [title, setTitle] = useState("Terms of Use - ADSTREET");
  return (
    <>
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>
      <Box component={"div"}>
        <Container>
          <Box
            component={"h1"}
            sx={{
              textAlign: "center",
              mt: 5,
            }}
          >
            Terms of Use
          </Box>
          <Grid container>
            {/* Last Updated: 1st August 2023*/}
            <Grid item xs={12}>
              {data.map((item, index) => (
                <>
                  {/* Title */}
                  <Box
                    sx={{
                      marginTop: item?.style?.mTitle,
                    }}
                  >
                    <PolicyAndTerms title={item?.title} />
                  </Box>
                  {/* Paragraph 1*/}
                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                    }}
                  >
                    <PolicyAndTerms paragraph={item?.paragraph} />
                  </Box>
                  {/* Paragraph 2*/}
                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                    }}
                  >
                    <PolicyAndTerms paragraph={item?.paragraphTwo} />
                  </Box>

                  {/* text */}
                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                    }}
                  >
                    <PolicyAndTerms text={item?.text} />
                  </Box>

                  <Box
                    sx={{
                      marginTop: item?.style?.mPara,
                    }}
                  >
                    <PolicyAndTerms list={item?.listOne} />
                    <PolicyAndTerms list={item?.listTwo} />
                    <PolicyAndTerms list={item?.listThree} />
                  </Box>
                </>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Terms;
