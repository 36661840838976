import React, { useState } from 'react'
import { Box, Button, CardMedia, Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { images } from '../../assets';
import { useForm } from 'react-hook-form';
import { colors } from '../../assets/colors';
import styled from '@emotion/styled';
import EventServices from '../../api/events/EventServices';
import { SuccessToaster } from '../../components/toaster/Toaster';

const InputField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: colors.primaryColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primaryColor,
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#c4c4c4",
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: colors.primaryColor,
  },
  "&:hover .MuiInputLabel-root.Mui-disabled": {
    color: "#c4c4c4",
  },
  "&:hover .MuiInputLabel-root": {
    color: colors.primaryColor,
  }
})

const options = [
  {
    _id: 1,
    name: "VIP"
  },
  {
    _id: 2,
    name: "Premium"
  },
  {
    _id: 3,
    name: "Standard"
  }
];

const prices = [
  {
    type: "Seat",
    prices: [
      {
        _id: 1,
        type: "VIP",
        price: 30000
      },
      {
        _id: 2,
        type: "Premium",
        price: 25000
      },
      {
        _id: 3,
        type: "Standard",
        price: 23000
      }
    ],
  },
  {
    type: "Table",
    prices: [
      {
        _id: 1,
        type: "VIP",
        price: 300000
      },
      {
        _id: 2,
        type: "Premium",
        price: 250000
      },
      {
        _id: 3,
        type: "Standard",
        price: 200000
      },
    ],
  },
];

const type = ["Table", "Seat"];

function Dragons() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [tableOrSeats, setTableOrSeats] = useState(null);
  const [price, setPrice] = useState(null);

  const handleFormSubmit = async (formData) => {
    const obj = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phone,
      companyName: formData.companyName,
      noOfTables: selectedType == "Table" ? tableOrSeats : 0,
      noOfSeats: selectedType == "Seat" ? tableOrSeats : 0,
      category: selectedCategory?.name,
      price: price,
    }
    try {
      const result = await EventServices.createDragonEvent(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setSelectedCategory(null);
        setSelectedType(null);
        setPrice(null);
        reset();
        SuccessToaster(result?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrice = (quantity, category = selectedCategory, type = selectedType) => {
    if (category && type && quantity) {
      const selectedPriceType = prices.find(item => item.type === type);
      if (selectedPriceType) {
        const priceDetail = selectedPriceType.prices.find(item => item._id === category._id);
        if (priceDetail) {
          const totalPrice = priceDetail.price * quantity;
          setPrice(totalPrice);
        }
      }
    } else {
      setPrice(null);
    }
  };

  const handleCategoryChange = (e) => {
    const selected = options.find(item => item._id == e.target.value);
    setSelectedCategory(selected);
    handlePrice(tableOrSeats, selected, selectedType);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    handlePrice(tableOrSeats, selectedCategory, e.target.value);
  };

  const handleQuantityChange = (e) => {
    setTableOrSeats(e.target.value);
    handlePrice(e.target.value, selectedCategory, selectedType);
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <CardMedia
          component={"img"}
          src={images.dragons}
          sx={{
            width: "100%",
            height: "100%"
          }}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Container>
          <Grid
            container
            spacing={2}
            sx={{ p: 2 }}
            component={"form"}
            onSubmit={handleSubmit(handleFormSubmit)}
            justifyContent={"center"}
          >
            <Grid item md={12}>
              <Typography variant='h3' sx={{ textAlign: "center", fontSize: { md: "48px", xs: "40px" } }}>Registration Form</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                variant={"outlined"}
                label={"Name"}
                {...register("name", {
                  required: "Your name"
                })}
                error={errors?.name && true}
                helperText={errors?.name?.message}
              />

            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                variant={"outlined"}
                label={"Email"}
                {...register("email", {
                  required: "Your email"
                })}
                error={errors?.email && true}
                helperText={errors?.email?.message}
              />

            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                variant={"outlined"}
                label={"Phone"}
                {...register("phone", {
                  required: "Your phone"
                })}
                error={errors?.phone && true}
                helperText={errors?.phone?.message}
              />

            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                variant={"outlined"}
                label={"Company Name"}
                {...register("companyName", {
                  required: "Your company name"
                })}
                error={errors?.companyName && true}
                helperText={errors?.companyName?.message}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                select
                fullWidth
                variant={"outlined"}
                label={"Category"}
                value={selectedCategory != null ? selectedCategory?._id : null}
                {...register("category", {
                  required: "Category",
                  onChange: (e) => handleCategoryChange(e)
                })}
                error={errors?.category && true}
                helperText={errors?.category?.message}
              >
                {options.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}

              </InputField>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                select
                fullWidth
                variant={"outlined"}
                label={"Table OR Seat"}
                value={selectedType}
                {...register("type", {
                  required: "Table OR Seat",
                  onChange: (e) => handleTypeChange(e)
                })}
                error={errors?.type && true}
                helperText={errors?.type?.message}
              >
                {type.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}

              </InputField>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                disabled={(selectedCategory && selectedType) ? false : true}
                fullWidth
                variant={"outlined"}
                label={
                  selectedType == "Table" ? "No of Table"
                    : selectedType == "Seat" ? "No of Seat"
                      : "Select Table OR Seat"
                }
                {...register("tables", {
                  required: "Number of tables",
                  onChange: (e) => handleQuantityChange(e)
                })}
                error={errors?.tables && true}
                helperText={errors?.tables?.message}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                value={
                  selectedCategory != null && selectedType != null && tableOrSeats != null && tableOrSeats != ""
                    ? `${price} PKR` : selectedCategory == null || selectedType != null ? ""
                      : selectedCategory != null || selectedType == null ? ""
                        : ""
                }
                fullWidth
                variant={"outlined"}
                label={"Price"}
                {...register("price", {
                  required: price == null ? "Price" : false,
                })}
                error={errors?.price && true}
                helperText={errors?.price?.message}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  variant='contained'
                  type='submit'
                  sx={{
                    background: colors.primaryColor,
                    border: "2px solid transparent",
                    p: "6px 24px",
                    fontWeight: 600,
                    borderRadius: "8px",
                    boxShadow: "none",
                    ":hover": {
                      boxShadow: "none",
                      background: colors.white,
                      border: `2px solid ${colors.primaryColor}`,
                      color: colors.blackShade
                    }
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Dragons