import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";
import Home from "./pages/homepage/Home";
import route from "./routes/componentRoutes";
import { createTheme, ThemeProvider } from "@mui/material";
import "@fontsource/montserrat";
import { v4 as uuidv4 } from "uuid";
import { ToasterContainer } from "./components/toaster/Toaster";
const theme = createTheme({
  typography: {
    fontFamily: "montserrat, sans-serif",
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ToasterContainer />
        <Header />
        <Box component={"main"}>
          <Routes>
            <Route path="/" element={<Home />} />
            {route.map((item, index) => (
              <Route key={uuidv4()} path={item.path} element={item.component} />
            ))}
          </Routes>
        </Box>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
