export const data = [
  {
    title: "Last Updated: 1st August 2023",
    paragraph:
      "Welcome to the ADSTREET app (“App”). At ADSTREET, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, share, and protect your personal data. By accessing and using the App, you agree to the practices described in this Privacy Policy.",
    style: {
      mTitle: "50px",
      mPara: "50px",
    },
  },

  // Information we collect

  {
    title: "1. Information We Collect:",
    paragraph: "We collect various types of information to provide you with a seamless and personalized experience within the App:",
    style: {
      mTitle: "40px",
      mPara: "10px",
    },
  },

  // Personal information

  {
    title: "1.1 Personal Information:",
    paragraph: "When you sign up for an account, we may collect your name, email address, phone number, and other relevant contact information.",
    paragraphTwo: "If you choose to make transactions through the App, we may collect payment information, billing address, and other necessary details.",

    style: {
      mTitle: "40px",
      mPara: "10px",
    },
  },

  // Usage information

  {
    title: "1.2 Usage Information:",
    paragraph: "We collect information about how you use the App, including your interactions, preferences, and activities.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Device information

  {
    title: "1.3 Device Information:",
    paragraph: "We may collect information about the device you use to access the App, including device type, operating system, and unique device identifiers.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // How we use your information

  {
    title: "2. How We Use Your Information:",
    text: "We use the information collected for various purposes, including but not limited to:",
    listOne: "Providing and improving our services, features, and functionalities.",
    listTwo: "Customizing your experience within the App.",
    listThree: "Communicating with you about updates, promotions, and important information.",
    listFour: "Processing transactions and payments.",
    listFive: "Analyzing usage patterns to enhance user experience and optimize our services.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Data sharing

  {
    title: "3. Data Sharing:",
    text: "We do not share your personal information with third parties except in the following cases:",
    listOne: "With your consent.",
    listTwo: "To provide services or carry out transactions requested by you.",
    listThree: "With service providers, partners, or affiliates who assist us in operating and maintaining the App.",
    listFour: "With service providers, partners, or affiliates who assist us in operating and maintaining the App.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Data security

  {
    title: "4. Data Security:",
    paragraph:
      "We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no data transmission or storage can be guaranteed to be 100% secure. We strive to protect your data, but we cannot guarantee absolute security.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Cookies and tracking technologies

  {
    title: "5. Cookies and Tracking Technologies:",
    paragraph:
      "We use cookies and similar tracking technologies to enhance your experience within the App, collect usage data, and improve our services. You can modify your browser settings to manage cookies, but note that some features of the App may not function properly if cookies are disabled.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Your choices

  {
    title: "6. Your Choices:",
    text: "You have the right to:",
    listOne: "Access and update your personal information.",
    listTwo: "Opt-out of receiving promotional emails or notifications.",
    listThree: "Delete your account and associated data, subject to legal obligations.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Children's privacy

  {
    title: "7. Children’s Privacy:",
    paragraph:
      "The App is not intended for individuals under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will take steps to promptly delete the information.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Change the privacy

  {
    title: "8. Changes to this Privacy Policy:",
    paragraph: "We may update this Privacy Policy from time to time. We will notify you of any significant changes through the App or other communication methods.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Contact us

  {
    title: "9. Contact Us:",
    paragraph: "If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at  admin@adstreet.com.pk",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Data use for marketing

  {
    title: "10. Data Use for Marketing:",
    paragraph:
      "We may use your personal information for marketing purposes within ADSTREET without seeking your explicit consent. This includes sending you promotional offers, updates, and news about the App and related services. However, you have the right to opt out of receiving such marketing communications at any time. You can manage your communication preferences within your account settings or by following the instructions provided in the communication.",
    paragraphTwo:
      "By using the ADSTREET app, you acknowledge and consent to the practices described in this Privacy Policy. It is important to review this Privacy Policy periodically for any updates.",
    paragraphThree: "Thank you for trusting ADSTREET with your personal information.",
    style: {
      mTitle: "50px",
      mPara: "10px",
      mbPara: "50px",
    },
  },
];
