export const navigation = [
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Adleaks",
    path: "/adleaks",
  },
  {
    name: "Adpro",
    path: "/adpro",
  },
  {
    name: "Admeet",
    path: "/admeet",
  },
];
