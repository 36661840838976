import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuItem } from "@mui/material";
import { Typography } from "@mui/material";
import { colors } from "../../assets/colors/index";
import { useNavigate } from "react-router-dom";
export default function AnchorTemporaryDrawer({ anchorElNav, handleCloseNavMenu, navigation }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navigate = useNavigate();

  // const list = (anchor) => (
  //   <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
  //     <List>
  //       {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //     <List>
  //       {["All mail", "Trash", "Spam"].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  return (
    <div>
      {["top"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon sx={{ colors: "red !important" }} />
          </Button>
          <Drawer
            sx={{
              position: "absolute",
              top: "100px",
            }}
            anchor={anchor}
            open={state[anchor]}
            onClick={toggleDrawer(anchor, false)}
            anchorElNav={anchorElNav}
          >
            {/* {list(anch  or)} */}

            {navigation.map((page, ind) => (
              <MenuItem
                className="slide"
                key={ind}
                onClick={() => {
                  navigate(page.path);
                  handleCloseNavMenu();
                }}
                sx={{
                  p: "12px 16px",
                  transition: `all .5s ease`,
                  backgroundColor: "black",
                  ":hover": {
                    backgroundColor: "red", // Set your hover background color
                  },
                  transition: "all 0.5s ease",
                }}
              >
                <Typography
                  sx={{
                    color: colors.white,
                    transform: `translateY(0)`,
                  }}
                >
                  {page.name}
                </Typography>
              </MenuItem>
            ))}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
