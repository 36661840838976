import ServicesRoutes from "./Services.routes";
import { get } from "../index";

const ServicesService = {
  getAllService: async () => {
    const result = await get(ServicesRoutes.getAllServices);
    return result;
  },
};

export default ServicesService;
