import { Container, Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/colors/index";
import BasicChips from "../../components/blogDetailsChip";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../../axios";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BlogSM from "../../components/blogSocialMedia/BlogSM";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import parse from "html-react-parser";
import { Button } from "@mui/material";
import BlogServices from "../../api/blog/BlogService";
import "./index.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

const BlogDetails = () => {
  const location = useLocation();
  const item = location.state;
  console.log("🚀 ~ BlogDetails ~ item:", item)

  const facebookUrl = "https://www.facebook.com";
  const linkedInUrl = "https://www.linkedin.com";
  const twitterUrl = "https://www.twitter.com";
  const whatsAppUrl = "https://www.facebook.com";

  // console.log("BlogServices", BlogServices.postInterest);

  const submitInterest = async (id) => {
    try {
      const result = await BlogServices.postInterest(id);
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const htmlContent = item?.content;

  // Create a temporary div element to parse the HTML content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;
  let oembedTag;
  let oembedUrl;

  if (tempDiv.querySelector("oembed")) {
    oembedTag = tempDiv.querySelector("oembed");
    oembedUrl = oembedTag.getAttribute("url");
  }

  let embedUrl = oembedUrl?.replace(/youtu\.be\/([^\?]+)/, "youtube.com/watch?v=$1");
  embedUrl = embedUrl?.replace("/watch?v=", "/embed/");

  console.log(embedUrl);

  return (
    <>
      <Container>
        <Box component={"article"}>
          <Grid container margin={"0 auto"} sx={{ width: "87%" }}>
            {/* Blog details */}
            <Grid item xs={12}>
              <Grid container mt={0} justifyContent={"space-between"} spacing={1}></Grid>
            </Grid>
            {/* Image */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    component={"img"}
                    src={baseUrl + item?.image}
                    sx={{
                      width: { xs: "100%" },
                      height: { xs: "auto", sm: "300px", lg: "600px" },
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "block",
                      margin: "0 auto",
                      mt: 4,
                      objectFit: "contain",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3} md={1.8} mt={2}>
              <Grid container>
                <Grid item columnGap={"10px"}>
                  <FacebookShareButton url={facebookUrl}>
                    <a className="btn btn-icon btn-facebook fb-title">
                      <i className="bi bi-facebook class-two"></i>
                      <span className="class-three">Facebook</span>
                    </a>
                  </FacebookShareButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3} md={1.6} mt={2}>
              <Grid container>
                <Grid item>
                  <TwitterShareButton url={twitterUrl}>
                    <a class="btn btn-icon btn-twitter">
                      <i class="fa fa-twitter"></i>
                      <span>Twitter</span>
                    </a>
                  </TwitterShareButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3} md={1.7} mt={2}>
              <Grid container>
                <Grid item>
                  <LinkedinShareButton url={linkedInUrl}>
                    <a class="btn btn-icon btn-linkedin">
                      <i class="bi bi-linkedin"></i>
                      <span>LinkedIn</span>
                    </a>
                  </LinkedinShareButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3} md={3} mt={2}>
              <Grid container>
                <Grid item>
                  <WhatsappShareButton url={whatsAppUrl}>
                    <a class="btn btn-icon btn-whatsapp">
                      <i class="bi bi-whatsapp"></i>
                      <span>WhatsApp</span>
                    </a>
                  </WhatsappShareButton>
                </Grid>
              </Grid>
            </Grid>
            {/* Title */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    component={"h1"}
                    sx={{
                      m: 0,
                      mt: 4,
                      width: "100%",
                      fontSize: { xs: "24px", md: "32px" },
                    }}
                  >
                    {item?.title}
                  </Box>
                </Grid>
              </Grid>
              {/* date and comments */}
              <Grid item xs={12} sm={6} md={12}>
                {/* Date */}
                <Grid container justifyContent={"space-between"}>
                  {/* Comments */}
                  {/* <Grid item xs={2}>
                    <Grid container>
                      <Grid item xs={1} sm={1.5} md={1.2}>
                        <TextsmsOutlinedIcon sx={{ fontSize: "15px" }} />
                      </Grid>
                      <Grid item xs={9}>
                        <BasicChips content={"No Comments"} sx={{ fontSize: "10px" }} />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  {/* Interest button */}
                  {/* <Grid item xs={2}>
                    {item?.type === "Adleaks" && (
                      <Button
                        onClick={() => submitInterest(item._id)}
                        sx={{
                          backgroundColor: colors.primaryColor,
                          color: colors.white,
                          "&:hover": {
                            backgroundColor: colors.primaryColor,
                          },
                        }}
                      >
                        Interest
                      </Button>
                    )}
                  </Grid> */}
                </Grid>
              </Grid>
              {/* Comments */}
            </Grid>
            {/* <Box id="social-platforms"></Box> */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} mt={2}>
                  <Grid container>
                    <Grid item>
                      <CalendarTodayIcon sx={{ fontSize: "17px" }} />
                    </Grid>
                    <Grid item xs={4}>
                      <BasicChips
                        sx={{
                          width: "100px",
                          backgroundColor: "red",
                          display: "block",
                          fontSize: "15px",
                        }}
                        content={moment(item?.date).format("LL")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {item?.content && (
                    <Box
                      component={"p"}
                      sx={{
                        fontSize: { xs: "10px", md: "17px" },
                        color: colors.cardTitleColor,
                        pb: 5,
                      }}
                      className="parsed-html"
                    >
                      {parse(item?.content)}
                    </Box>
                  )}
                  {/* <Box sx={{ pb: 4 }}>
                    {embedUrl && (
                      <iframe
                        className="fetched-video"
                        width="100%"
                        height="515"
                        display="block"
                        src={embedUrl}
                        title="Video"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    )}
                  </Box> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default BlogDetails;
