import React, { useState } from 'react'
import { Box, Button, CardMedia, Container, Grid, TextField, Typography, MenuItem } from '@mui/material';
import styled from '@emotion/styled';
import { images } from '../../assets';
import { colors } from '../../assets/colors';
import { useForm } from 'react-hook-form';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import EventServices from '../../api/events/EventServices';
import { SuccessToaster } from '../../components/toaster/Toaster';

const InputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: colors.primaryColor,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.primaryColor,
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#c4c4c4",
    }
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: colors.primaryColor,
  },
  "&:hover .MuiInputLabel-root.Mui-disabled": {
    color: "#c4c4c4",
  },
  "&:hover .MuiInputLabel-root": {
    color: colors.primaryColor,
  }
})

const options = [
  {
    _id: 1,
    name: "VIP"
  },
  {
    _id: 2,
    name: "Premium"
  },
  {
    _id: 3,
    name: "Standard"
  }
];

const prices = [
  {
    _id: 1,
    price: 30000
  },
  {
    _id: 2,
    price: 25000
  },
  {
    _id: 3,
    price: 20000
  }
];

function Advision() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [price, setPrice] = useState(null);
  const [noOfSeats, setNoOfSeats] = useState(null);

  const handleFormSubmit = async (formData) => {
    const obj = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phone,
      companyName: formData.companyName,
      noOfTables: formData.tables,
      category: selectedCategory?.name,
      price: price,
    }
    try {
      const result = await EventServices.createAdvisionEvent(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setSelectedCategory(null);
        setPrice(null);
        reset();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrice = (seats, category = selectedCategory) => {
    if (category && seats) {
      const selectedPrice = prices.find((item) => item._id === category._id)?.price;
      const totalPrice = selectedPrice * seats;
      setPrice(totalPrice);
    } else {
      setPrice(null);
    }
  };

  const handleCategoryChange = (e) => {
    const selected = options.find((item) => item._id == e.target.value);
    setSelectedCategory(selected);
    handlePrice(noOfSeats, selected);
  };

  const handleSeatsChange = (e) => {
    const seats = e.target.value;
    setNoOfSeats(seats);
    handlePrice(seats);
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <CardMedia
          component={"img"}
          src={images.advision}
          sx={{
            width: "100%",
            height: "100%"
          }}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Container>
          <Grid
            container
            spacing={2}
            sx={{ p: 2 }}
            component={"form"}
            onSubmit={handleSubmit(handleFormSubmit)}
            justifyContent={"center"}
          >
            <Grid item md={12}>
              <Typography variant='h3' sx={{ textAlign: "center", fontSize: { md: "48px", xs: "40px" } }}>
                Registration Form
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                variant={"outlined"}
                label={"Name"}
                {...register("name", {
                  required: "Your name"
                })}
                error={errors?.name && true}
                helperText={errors?.name?.message}
              />

            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                variant={"outlined"}
                label={"Email"}
                {...register("email", {
                  required: "Your email"
                })}
                error={errors?.email && true}
                helperText={errors?.email?.message}
              />

            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                variant={"outlined"}
                label={"Phone"}
                {...register("phone", {
                  required: "Your phone"
                })}
                error={errors?.phone && true}
                helperText={errors?.phone?.message}
              />

            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                variant={"outlined"}
                label={"Regd. Company Name & NTN (if any)"}
                {...register("companyName", {
                  required: "Your company name"
                })}
                error={errors?.companyName && true}
                helperText={errors?.companyName?.message}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                select
                fullWidth
                variant={"outlined"}
                label={"Category"}
                value={selectedCategory != null ? selectedCategory?._id : null}
                {...register("category", {
                  required: "Category",
                  onChange: (e) => handleCategoryChange(e)
                })}
                error={errors?.category && true}
                helperText={errors?.category?.message}
              >
                {options.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}

              </InputField>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                fullWidth
                disabled={selectedCategory == null ? true : false}
                variant={"outlined"}
                label={"No of Seats"}
                {...register("tables", {
                  required: "Number of seats",
                  onChange: (e) => handleSeatsChange(e)
                })}
                error={errors?.tables && true}
                helperText={errors?.tables?.message}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <InputField
                    value={
                      selectedCategory != null && noOfSeats != null && noOfSeats != "" ? `${price} PKR`
                        : selectedCategory == null || noOfSeats != null ? ""
                          : selectedCategory != null || noOfSeats == null ? ""
                            : ""
                    }
                    fullWidth
                    variant={"outlined"}
                    label={"Price"}
                    {...register("price", {
                      required: price == null ? "Price" : false,
                    })}
                    error={errors?.price && true}
                    helperText={errors?.price?.message}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  variant='contained'
                  type='submit'
                  sx={{
                    background: colors.primaryColor,
                    border: "2px solid transparent",
                    p: "10px 24px",
                    fontWeight: 600,
                    borderRadius: "8px",
                    boxShadow: "none",
                    ":hover": {
                      boxShadow: "none",
                      background: colors.white,
                      border: `2px solid ${colors.primaryColor}`,
                      color: colors.blackShade
                    }
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Advision