export const colors = {
  primaryColor: "#EC2028",
  textColor: "#000000",
  white: "#FFFFFF",
  menuBgColor: "#969697",
  facebookIconColor: "#0C83E8",
  instagramIconColor: "#F3047C",
  twitterIconColor: "#1C9BE9",
  tiktokIconColor: "#F12E56",
  linkedinIconColor: "#007AB5",
  footerSectionTwoBgColor: "#252525",
  cardsDataColor: "#B8BAC5",
  darkGrey: "#58585A",
  orange: "#EA7826",
  yellow: "#F1B815",
  lightBlue: "#C7EAFC",
  darkGrey2: "#5A595C",
  blackShade: "#221F1F",
  maroon: "#821617",
  adMeetTextColor: "#535252",
  adProInputBg: "#0B0B0B",
  footerSectionOneBgColor: "#000000",
  privacyAndPolicyColor: "#7A7A7A",
  blogFbBgColor: "#36518A",
  blogFbHoverColor: "#366CDD",
  blogTwitterBgColor: "#1C96E8",
  blogTwitterHoverBgColor: "#00CFFF",
  blogTwitterIconBgColor: "#1DA1F2", //
  blogTwitterIconHoverBgColor: "#00CFFF", //
  blogLinkedInBgColor: "#007ab5",
  blogLinkedInHoverBgColor: "#0096FF",
  blogLinkedInIconBgColor: "#0077B5",
  blogLinkedInHoverIconBgColor: "#009BFF",
  blogWhatsAppBgColor: "#41C051",
  blogWhatsAppIcongBgColor: "#25D366",
  blogWhatsAppIcongHoverBgColor: "#00FF54",
  blogWhatsAppHoverBgColor: "#00FC4C",
  blogCardsTextColor: "#848684",
  cardTitleColor: "#54595F",
  blogTextColor: "#827E7A",
};
