import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid } from "@mui/material";
import * as projectImages from "../../assets/index";
import { colors } from "../../assets/colors/index";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import ServicesService from "../../api/services/ServicesService";
import AdproService from "../../api/adpro/AdproService";
import { useForm } from "react-hook-form";
import IndustriesService from "../../api/industries/IndustriesService";
import { Helmet } from "react-helmet";
import gettingCategories from "../../api/category/CategoryService";
import { SuccessToaster } from "../../components/toaster/Toaster";
import Banner from "../../components/banner/Banner";
import gettingBanner from "../../api/banner/BannerService";

const AdPro = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [filePath, setFilePath] = useState("");
  const [services, setServices] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [title, setTitle] = useState("ADPRO Services - ADSTREET");
  const [banner, setBanner] = useState([]);

  // getting category

  const baseUrl = "https://adstreet.mangotech-api.com";

  const getCategory = async () => {
    try {
      const result = await gettingCategories.getCategory(1, 100);
      let adproResult = result?.data?.categories.filter((value) => {
        return value.type === "adpro";
      });
      console.log("Adpro result", adproResult);
      if (result.responseCode === 200) {
        setCategory(adproResult);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // console.log("Category =>",category)

  const getAllIndustries = async () => {
    try {
      const result = await IndustriesService.getAllIndustries();
      if (result.responseCode === 200) {
        setIndustries(result?.data?.industry);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleBanner = async () => {
    try {
      let result = await gettingBanner.getBanner();
      if (result.responseCode === 200) {
        console.log("Banner Result =>", result);
        let images = result?.data?.banners.filter((item, index) => {
          return item?.type === "adpro";
        });
        setBanner(images);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const bannerImg = [
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
    {
      img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
    },
  ];

  const getAllServices = async () => {
    try {
      const result = await ServicesService.getAllService();
      if (result.responseCode === 200) {
        setServices(result?.data?.service);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const result = await AdproService.uploadFile(formData);
      if (result.responseCode === 200) {
        setFilePath(result.data.fileUrl);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const submitForm = async (formData) => {
    const obj = {
      name: formData.fullName,
      phoneNumber: formData.phone,
      email: formData.email,
      companyName: formData.company,
      industry: selectedIndustry?._id,
      service: selectedService?._id,
      category: selectedCategory?._id,
      budget: formData.budget,
      timeline: formData.timeline,
      details: formData.requirement,
      links: formData.transfer,
      file: filePath,
    };
    try {
      const result = await AdproService.register(obj);
      if (result.responseCode === 200) {
        SuccessToaster("Form submitted successfully");
        reset();
        setSelectedIndustry(null);
        setSelectedCategory(null);
        setSelectedService(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCategory();
    getAllServices();
    getAllIndustries();
    handleBanner();
  }, []);
  return (
    <>
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>
      {/* Banner section start */}
      {/* <Container> */}
      <Box component={"section"} sx={{ width: { xs: "100%", md: "80%" }, margin: "0 auto" }}>
        <Banner baseUrl={baseUrl} images={banner} />
      </Box>
      {/* </Container> */}
      {/* Banner section end */}
      <Box
        component={"main"}
        sx={{
          backgroundImage: `url(${projectImages.images.adProBgImg})`,
          width: "100%",
          height: "auto",
          backgroundPosition: "center",
          backgroundSize: "cover",
          mt: 4,
        }}
      >
        <Grid container paddingLeft={{ xs: "10px", md: "15px" }}>
          <Grid item xs={10} md={3.5}>
            <Box
              component={"img"}
              src={projectImages.images.adProLogo}
              sx={{
                width: "100%",
                marginTop: "50px",
              }}
            />
          </Grid>
        </Grid>
        {/* Adstreet one stop shop */}
        <Grid container paddingLeft={{ xs: "10px", md: "15px" }}>
          <Grid item>
            <Box
              component={"h2"}
              sx={{
                textTransform: "uppercase",
                fontSize: { xs: "20px", md: "26px" },
                color: colors.white,
                fontWeight: "normal",
                margin: "13px 0",
              }}
            >
              Adstreet one stop shop
            </Box>
          </Grid>
        </Grid>
        {/* Adpro services text */}
        <Grid container paddingLeft={{ xs: "10px", md: "15px" }}>
          <Grid item xs={11} md={10}>
            <Box
              component={"p"}
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: colors.white,
                margin: "5px 0",
              }}
            >
              ADPRO Services is our one-stop-shop for all your marketing needs! We'll connect you with the right people to handle your marketing, and ensure that your brand gets the attention it
              deserves. Join us and let us take your brand to the next level!
            </Box>
          </Grid>
        </Grid>
        {/* Form */}
        <Grid container paddingLeft={{ xs: "10px", md: "15px" }} marginLeft={""}>
          <Box component={"form"} onSubmit={handleSubmit(submitForm)} sx={{ width: "100%" }}>
            {/* Your full name */}
            <Grid item xs={11.7} md={6} sx={{ margin: "15px 0" }}>
              <InputField
                placeholder="Your Full Name (Required)"
                register={register("fullName", {
                  required: "Your Full Name",
                })}
                error={errors?.fullName && true}
                helperText={errors?.fullName?.message}
              />
            </Grid>
            {/* Your phone number */}
            <Grid item xs={11.7} md={6} sx={{ margin: "15px 0" }}>
              <InputField
                placeholder="Your Phone Number (Required)"
                register={register("phone", {
                  required: "Your Phone Number",
                })}
                error={errors?.phone && true}
                helperText={errors?.phone?.message}
              />
            </Grid>
            {/* Your Email Address */}
            <Grid item xs={11.7} md={6} sx={{ margin: "15px 0" }}>
              <InputField
                placeholder="Your Email Address (Required)"
                register={register("email", {
                  required: "Your Email Address",
                })}
                error={errors?.email && true}
                helperText={errors?.email?.message}
              />
            </Grid>
            {/* Company name */}
            <Grid item xs={11.7} md={6} sx={{ margin: "15px 0" }}>
              <InputField
                placeholder="Your Company Name(Required)"
                register={register("company", {
                  required: "Your Company Name",
                })}
                error={errors?.company && true}
                helperText={errors?.company?.message}
              />
            </Grid>
            {/* Mention your industry */}
            <Grid item xs={11.7} md={6}>
              <InputField
                placeholder={"Mention Your Industry(Required)"}
                register={register("industry", {
                  required: "Enter Industry"
                })}
              />
            </Grid>
            {/* Mention services */}
            <Grid item xs={11.7} md={6} margin={"5px 0"} sx={{ margin: "15px 0" }}>
              <SelectField selectType={"adpro"} data={category} value={selectedService !== null ? selectedService?._id : ""} setValue={setSelectedService} />
            </Grid>
            {/* Mention category */}
            {/* <Grid item xs={11.7} md={7.2} margin={"5px 0"} sx={{ margin: "15px 0" }}>
              <SelectField selectType={"adproCat"} data={category} value={selectedCategory !== null ? selectedCategory?._id : ""} setValue={setSelectedCategory} />
            </Grid> */}
            {/* Expected budget */}
            <Grid item xs={11.7} md={6} margin={"0"}>
              <InputField placeholder="Your Expected Budget (Optional)" register={register("budget")} />
            </Grid>
            {/* Expected timeline */}
            <Grid item xs={11.7} md={6} margin={"0"} sx={{ margin: "15px 0" }}>
              <InputField placeholder="Expected Timeline (Optional)" register={register("timeline")} />
            </Grid>
            {/* Requirement */}
            <Grid item xs={11.7} md={6} sx={{ margin: "0" }}>
              <InputField
                placeholder="Requirement (Optional)"
                register={register("requirement")}
                multiline
                rows={8}
                maxRows={40}
                InputProps={{
                  style: {
                    color: colors.white,
                  },
                }}
              />
            </Grid>
            {/* Transfer download link */}
            <Grid item xs={11.7} md={6} sx={{ margin: "15px 0" }}>
              <InputField placeholder="Enter We Transfer download link if any:" register={register("transfer")} />
            </Grid>
            <Box
              component={"h5"}
              sx={{
                color: colors.white,
                fontWeight: "light",
              }}
            >
              Upload Brief
            </Box>
            {/* Choose file */}
            <Box component={"input"} onChange={(e) => handleUpload(e.target.files[0])} type="file" name="image" sx={{ color: colors.white, fontSize: "large" }} />
            <Box
              component={"h5"}
              sx={{
                color: colors.white,
                fontWeight: "light",
              }}
            >
              File Type Allowed: .pdf .docx .xslx
            </Box>
            <Grid item xs={11.7} md={7}>
              <Button
                type="submit"
                size="small"
                sx={{
                  color: colors.white,
                  fontWeight: "medium",
                  textTransform: "capitalize",
                  fontSize: "22px",
                  border: `1px solid ${colors.primaryColor}`,
                  width: "100%",
                  padding: "0",
                  marginBottom: "20px",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default AdPro;
